<template>
  <div class="detaile-table">
    <m-table :data="data" :loading="loading" :pagination="pagination" @currentChange="changePage">
      <el-table-column label="邮箱">
        <template #default="tableItem">
          <div class="email-wrap">
            <img :src="tableItem.row.country_img" alt="" width="24" height="16" />
            <div class="m-bold m-omit" :title="tableItem.row.payment_email">
              {{ tableItem.row.payment_email }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="订单编号">
        <template #default="tableItem">
          <div class="m-omit" :title="tableItem.row.itemno">{{ tableItem.row.itemno }}</div>
        </template>
      </el-table-column>
      <el-table-column label="支付时间">
        <template #default="tableItem">
          {{ parseTime(tableItem.row.pay_time * 1000) }}
        </template>
      </el-table-column>
      <el-table-column label="优惠券使用状态">
        <template #default="tableItem">
          <el-tag v-if="tableItem.row.coupon_status == 1" type="danger">未使用</el-tag>
          <el-tag v-else-if="tableItem.row.coupon_status == 0" type="info">不可用</el-tag>
          <el-tag type="warning" v-else> 已使用 </el-tag>
          <el-tooltip placement="right" effect="light" v-if="tableItem.row.is_passon == 1">
            <template #content>
              <div style="margin-bottom: 4px">转赠邮箱:</div>
              <div style="margin-bottom: 8px">{{ tableItem.row.passon_email }}</div>
              <div style="margin-bottom: 4px">转赠时间:</div>
              <div style="margin-bottom: 4px">
                {{ parseTime(tableItem.row.forward_time * 1000) }}
              </div>
            </template>
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="邮件发送状态">
        <template #default="tableItem">
          <div class="control" v-if="tableItem.row.is_send == 0">
            <el-tag type="info">未发送</el-tag>
            <div
              class="m-link"
              @click="send(tableItem.row)"
              :style="tableItem.row.loading ? { pointerEvents: 'none' } : ''"
            >
              <i v-if="tableItem.row.loading" class="el-icon-loading"></i>
              立即发送
            </div>
          </div>
          <div class="control" v-else-if="tableItem.row.is_send == 1">
            <el-tag>已发送</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="优惠券使用码">
        <template #default="tableItem">
          <div class="m-bold m-omit" :title="tableItem.row.coupon_code">
            {{ tableItem.row.coupon_code }}
          </div>
        </template>
      </el-table-column>
    </m-table>
  </div>
</template>

<script>
import MTable from '@/components/table/Table';
import { parseTime } from '../../../../tools';
import { sendNow } from '../../api/giftDetail';
import { ElMessage } from 'element-plus';
export default {
  components: {
    MTable,
  },
  props: ['data', 'loading', 'pagination'],
  setup(props, { emit }) {
    const changePage = cur => {
      emit('currentChange', cur);
    };

    const send = async row => {
      row.loading = true;
      const res = await sendNow({ id: row.id, loading: true });
      if (res.status) {
        ElMessage.success('发送成功');
      }
      row.is_send = 1;
      row.loading = false;
    };

    return {
      changePage,
      parseTime,
      send,
    };
  },
};
</script>

<style lang="scss" scoped>
.email-wrap {
  display: flex;
  align-items: center;
  img {
    margin-right: 10px;
  }
}
.control {
  display: inline-flex;
  align-items: center;
  flex-direction: column;

  .m-link {
    margin-top: 10px;
  }
}
.el-icon-question {
  font-size: 16px;
  margin-left: 8px;
  cursor: pointer;
}
</style>
