<template>
  <div class="gift-detail">
    <header>
      <m-page-header title="礼品卡明细" />
    </header>
    <main>
      <div class="module-detail">
        <div class="time-filter">
          <span class="title">时间 :</span>
          <el-select v-model="time" @change="timerFilter" size="small" style="width: 120px">
            <el-option
              v-for="item in timeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="cards">
          <div class="card-item">
            <m-form-skeleton type="mini-text" :loading="listLoading && !isSearch">
              <div class="card-content-wrap">
                <div class="card-icon">
                  <i class="giftcard-iconfont icon-yonghuhaoyou"></i>
                </div>
                <div class="cart-detail">
                  <div class="number">{{ cardData.user_num }}</div>
                  <div class="desc">累计购买用户</div>
                </div>
              </div>
            </m-form-skeleton>
          </div>
          <div class="card-item">
            <m-form-skeleton type="mini-text" :loading="listLoading && !isSearch">
              <div class="card-content-wrap">
                <div class="card-icon">
                  <i class="giftcard-iconfont icon-lipin"></i>
                </div>
                <div class="cart-detail">
                  <div class="number">{{ cardData.count_num }}</div>
                  <div class="desc">累积售卖礼品卡</div>
                </div>
              </div>
            </m-form-skeleton>
          </div>
          <div class="card-item">
            <m-form-skeleton type="mini-text" :loading="listLoading && !isSearch">
              <div class="card-content-wrap">
                <div class="card-icon">
                  <i class="giftcard-iconfont icon-youhuiquan"></i>
                </div>
                <div class="cart-detail">
                  <div class="number">{{ cardData.giftcard_tonum }}</div>
                  <div class="desc">累积礼品券使用数量</div>
                </div>
              </div>
            </m-form-skeleton>
          </div>
          <div class="card-item">
            <m-form-skeleton type="mini-text" :loading="listLoading && !isSearch">
              <div class="card-content-wrap">
                <div class="card-icon">
                  <i class="giftcard-iconfont icon-Dollar-circle-fill"></i>
                </div>
                <div class="cart-detail">
                  <div class="number">
                    {{
                      cardData.symbol_status === 1
                        ? cardData.symbol_left + cardData.money
                        : cardData.money + cardData.symbol_left
                    }}
                  </div>
                  <div class="desc">累积售卖金额</div>
                </div>
              </div>
            </m-form-skeleton>
          </div>
        </div>
      </div>
      <div class="module-list">
        <div class="control-bar">
          <div class="search-wrap">
            <detail-controlbar
              @search="search"
              @superFilter="superFilter"
              :query="searchQuery"
              @queryChange="queryChange"
            />
          </div>
        </div>
        <div class="list-wrap">
          <detail-table
            :data="dataList"
            :loading="listLoading && !isTimeFilter"
            :pagination="pagination"
            @currentChange="changePage"
          />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { ref } from 'vue';

import { getInitData } from '../api/giftDetail';
import MPageHeader from '@/components/pageHeader/PageHeader';
import MFormSkeleton from '@/components/formSkeleton/FormSkeleton';
import DetailTable from '../components/detailTable/DetailTable';
import DetailControlbar from '../components/detailControlbar/DetailControlbar';
export default {
  name: 'GiftDeatil',
  components: {
    MPageHeader,
    MFormSkeleton,
    DetailTable,
    DetailControlbar,
  },
  setup() {
    const time = ref('0');
    const cardData = ref({});
    const dataList = ref([]);
    const listLoading = ref(false);
    const pagination = ref({
      total: 0,
      pageSize: 10,
      currentPage: 1,
    });

    const isSearch = ref(false);
    const isTimeFilter = ref(false);

    // type 是 search 时，只更列表，type是 time 时，只更新卡片
    const setPageData = async (
      { page = 1, keyword = '', timeVal = '', filter = {} },
      type = '',
    ) => {
      isSearch.value = false;

      isTimeFilter.value = false;
      if (type === 'search') {
        isSearch.value = true;
      } else if (type === 'time') {
        isTimeFilter.value = true;
      }

      const res = await getInitData(
        {
          ...filter,
          keyword: keyword || filter.keyword || '',
          page_size: pagination.value.pageSize,
          page,
          time: timeVal,
        },
        listLoading,
      );
      if (!res.status) return;
      const { page_list, data, list } = res.data;

      pagination.value.total = parseInt(page_list.total);
      pagination.value.currentPage = page_list.page;

      cardData.value = data;

      dataList.value = list;
    };

    const searchQuery = ref('');
    const curSelectTimeVal = ref('0');

    const queryChange = val => {
      searchQuery.value = val;
    };
    // 搜索
    const search = data => {
      searchQuery.value = data;
      setPageData(
        {
          page: 1,
          keyword: searchQuery.value,
          timeVal: curSelectTimeVal.value,
        },
        'search',
      );
    };

    const filterData = ref({});
    // 高级筛选
    const superFilter = data => {
      filterData.value = data;
      searchQuery.value = '';

      setPageData(
        {
          page: 1,
          keyword: searchQuery.value,
          timeVal: curSelectTimeVal.value,
          filter: filterData.value,
        },
        'search',
      );
    };

    // 时间筛选
    const timerFilter = val => {
      curSelectTimeVal.value = val;
      setPageData(
        {
          page: 1,
          keyword: searchQuery.value,
          timeVal: curSelectTimeVal.value,
        },
        'time',
      );
    };

    // 分页
    const changePage = cur => {
      setPageData({
        page: cur,
        keyword: searchQuery.value,
        filter: filterData.value,
        timeVal: curSelectTimeVal.value,
      });
    };

    return {
      time,
      dataList,
      listLoading,
      pagination,
      searchQuery,
      changePage,
      setPageData,
      cardData,
      search,
      superFilter,
      timerFilter,
      isSearch,
      isTimeFilter,
      queryChange,
      timeOptions: [
        {
          value: '0',
          label: '全部',
        },
        {
          value: '1',
          label: '最近7天',
        },
        {
          value: '2',
          label: '最近15天',
        },
        {
          value: '3',
          label: '最近30天',
        },
      ],
    };
  },
  mounted() {
    this.setPageData({});
  },
};
</script>

<style lang="scss" scoped>
@import '../statics/scss/iconfont';
@import '@/assets/scss/tool';
main {
  padding: 20px;
  padding-bottom: 0;
  .module-detail {
    &::after {
      content: '';
      display: block;
      background-color: $darkGray;
      height: 10px;
      position: relative;
      left: -20px;
      width: calc(100% + 40px);
      margin-top: 20px;
    }
    .time-filter {
      margin-bottom: 18px;
      & > .title {
        margin-right: 10px;
      }
    }
    .cards {
      display: flex;
      justify-content: space-between;
      .card-item {
        margin-right: 10px;
        box-shadow: 0 2px 4px 0 rgba($color: #000000, $alpha: 0.05);
        border-radius: 4px;
        border: solid 1px #dedede;
        height: 144px;
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;

        &:last-of-type {
          margin-right: 0px;
        }

        .card-content-wrap {
          display: flex;
          .card-icon {
            width: 64px;
            height: 64px;
            background: #f3f3f3;
            border-radius: 50%;
            text-align: center;
            line-height: 64px;

            .giftcard-iconfont {
              font-size: 40px;

              &.icon-yonghuhaoyou {
                color: #40b6e2;
              }
              &.icon-lipin {
                color: #1a70a1;
              }
              &.icon-youhuiquan {
                color: #cd882f;
                font-size: 38px;
              }
              &.icon-Dollar-circle-fill {
                color: #aa53fc;
                font-size: 46px;
              }
            }
          }
          .cart-detail {
            margin-left: 30px;
            .number {
              font-size: 28px;
              margin-bottom: 14px;
            }
          }
        }
        @media screen and (max-width: 1590px) {
          & {
            height: 170px;
            .card-content-wrap {
              flex-direction: column;
              align-items: center;

              .cart-detail {
                margin: 0;
                text-align: center;
                padding-top: 10px;
              }
            }
          }
        }
      }
    }
  }
  .module-list {
    .control-bar {
      padding: 20px 0;
      display: flex;
    }
  }
}
</style>
